import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Rating from '../Rating/Rating';
import Button from '../../UI/Buttons/Button';
import Content from '../../UI/Content/Content';
import { reviewPath } from '../../../util/lib';
import { delay } from '../../../util/ExtraMethods';
import { uxTracking } from '../../../util/ux-tracking';
import TextContainer from '../../UI/Content/TextContainer';
import css from './ReviewForm.module.css';

const ReviewForm = (props) => {
  const { api, legacy, review, defaultTitle } = props;
  const badTitle = defaultTitle;
  const {code} = useParams();
	const history = useHistory();
  const [title, setTitle] = useState(defaultTitle);
  const [loading, setLoading] = useState();
  const [rating, setRating] = useState();
  const [feedback, setFeedback] = useState();

  useEffect(() => {
    const checkError = async () => {
      const path = reviewPath(code, null, legacy);
      if (!review) history.push(path);
    };
    checkError();
  }, [code, review, history, legacy]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await delay(1000);
      const data = {
        rating,
        feedback,
        channel: 'internal',
        reviewedAt: new Date(),
        partnerId: review.partnerId,
      };
      const response = await api({
        data,
        method: 'put',
        responseType: 'json',
        url: `/reviews/${review._id}`,
      });
      if (response.data && response.data.review) {
        uxTracking.identify(review.code, {
          phone: review.phone,
          rating: review.rating,
          feedback: review.feedback,
          firstName: review.firstName,
        });
      }
      setLoading(false);
      if (props.onReviewed) {
        props.onReviewed(data);
        return;
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const onChangeRating = (rating) => {
    if (rating < 2) setTitle(badTitle);
    else setTitle(defaultTitle);
    setRating(rating);
  };
  const onChangeFeedback = (e) => {
    setFeedback(e.target.value);
  };
  const disableSubmit = loading || !feedback || feedback.length < 3 || !rating;
  return (
    <Content>
      <TextContainer>{title}</TextContainer>
      <Rating onChange={onChangeRating} />
      <textarea
        rows="3"
        className={css.TextArea}
        onChange={onChangeFeedback}
        placeholder=""
      ></textarea>
      <Button type="main" onClick={onSubmit} disabled={disableSubmit}>
        {loading ? 'Sending feedback..' : 'Submit'}
      </Button>
    </Content>
  );
};
export default ReviewForm;
